/****************CHECKOUT***************/
.woocommerce form .form-row {
    width: 100% !important;
    margin-bottom: 10px;

    label {
      width: 100%;
    }
}
.woocommerce-checkout #payment div.payment_box input.input-text, .woocommerce-checkout #payment div.payment_box textarea {
    width: 100% !important;
    padding: 8px;
}
.woocommerce #payment .form-row select, .woocommerce-page #payment .form-row select {
    width: 100%;
    height: 30px;
}
.woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1,.woocommerce .col2-set .col-2, .woocommerce-page .col2-set .col-2 {
    float: left;
    width: 100%;
}
.custom-checkout h3 {
    background-color: #165f1c;  /****CHANGE TO COLOR YOU WANT TO USE FOR TITLE BACKGROUND ****/
    width: 45%;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    margin-top: 50px;
    color: #FFF;
    float: right;
}
.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea {
    padding: .6180469716em;
    background-color: #fff;
    color: #43454b;
    outline: 0;
    border: 0;
    -webkit-appearance: none;
    border-radius: 0px;
    box-sizing: border-box;
    font-weight: 400;
    border:solid 1px #c3c3c3;
}

#wc_checkout_add_ons {
    width: 45%;
    float: right;
    text-align: center;
}

.woocommerce-form__label.woocommerce-form__label-for-checkbox.checkbox {
  input {
    margin: 0;
  }

  span {
    margin-left: 25px;
  }
}

.woocommerce-checkout {
  h3 {
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 979px) {
  .custom-checkout h3 {
   width: 100%;
  }
}
