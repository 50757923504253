.woocommerce-MyAccount-navigation {

    ul {
        margin: 0;
        background-color: #222;
        padding: 15px;

        li {
            list-style-type: none;
            margin: 0;
            padding: 0;

            a {
                background-color: #222;
                color: #fff;
                padding: 10px;
                display: inline-block;
                width: 100%;
                text-decoration: none;

                &:hover, &.is-active {
                    text-decoration: underline;
                }
            }
        }

    }
}

.woocommerce form.checkout_coupon, .woocommerce form.login, .woocommerce form.register {
    border: 1px solid rgba(0,0,0,0.05);
    border-radius: 0px;
}

legend {
    float: left;
    margin-top: 30px;
    padding-bottom: 10px;
}

fieldset {
    margin-bottom: 20px;
}

 .woocommerce-MyAccount-navigation {
    margin-top: 0px;
}

.woocommerce-MyAccount-content {
   margin-top: 0px;
}

.woocommerce-account .addresses .title .edit {
    float: right;
    background-color: #0b84df;
    color: #fff;
    padding: 10px 15px;

    &:hover {
        text-decoration: none;
        background-color: #0a77ca;
    }
}

.woocommerce form.checkout_coupon, .woocommerce form.login, .woocommerce form.register {
    margin: 0 15px 30px 0;
}

.woocommerce-error {
    li {
        a {
            margin: 0px !important;
        }
    }
}

.woocommerce-MyAccount-content  {
	a.button {
		padding: 2px 20px !important;
    margin-right: 10px;
	}
}


// My account forms

.woocommerce-MyAccount-content {
  p.form-row {
    label {
      width: 25%;
    }
  }
}

.woocommerce .col2-set .col-1 {
  width: 49% !important;
  float: left;
}

.woocommerce .col2-set .col-2 {
  width: 49% !important;
  float: right !important;
}

footer {
  .woocommerce form.login, .woocommerce form.register {
    border: none;
    padding: 0;
    border-radius: 0px;
  }

  .woocommerce .col2-set .col-1 {
    width: 100% !important;
    float: left;
  }
}
