* {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	vertical-align: baseline;
}

html,
body,
#wrapper {
	height: 100%;
}

body > #wrapper {
	height: auto;
	min-height: 100%;
}

body {
	font-family: $font-family;
	font-size: 16px;
	background-color: $tertiary-color;
}

ul.layered-menu li a {
	font-size: 14px;
	font-family: $font-family;
	border-radius: 0px;
	background-color: $secondary-color;
	color: #00ade7;
}

ul.layered-menu li a:hover,
ul.layered-menu li.active a {
	background-color: $primary-color;
	color: #ffffff;
	text-transform: inherit;
}

.center {
	text-align: center;
	display: block;
	margin: 0 auto;
}

.eq-height {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.center {
	display: block;
	text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700;
}

h1 {
	font-size: 32px;
	font-weight: 700;
	font-family: $font-family;
	text-align: center;
	position: relative;
	color: $primary-color;
}

h2 {
	font-family: $font-family;
	font-size: 18px;
	color: $primary-color;
	margin: 20px 0 5px 0;
	font-weight: 600;
}

h3 {
	font-family: $font-family;
	font-size: 15px;
	color: $secondary-color;
	margin: 20px 0 5px 0;
	font-weight: 700;
}

p {
	line-height: 26px;
	font-size: 15px;
	font-family: $font-family;
	margin: 0 0 20px 0;
	color: #646464;
	font-weight: 400;
}

a {
	text-decoration: none;
	color: $primary-color;
	font-weight: 600;

	&:hover,
	&:focus {
		text-decoration: underline;
		color: $primary-color;
		font-weight: 600;
	}

}

ul,
ol {
	margin: 0 0 20px 30px;
}

strong {
	font-weight: 700;
	font-family: "Raleway", sans-serif;
	font-size: 17px;
}

img {
	height: auto;
	max-width: 100%;
	vertical-align: middle;
	border: 0;
	-ms-interpolation-mode: bicubic;
}

ul.layered-menu {
	li {
		a {
			&:hover {
				background-color: $primary-color;
				color: $white-color;
			}

		}

		&.active {
			a {
				background-color: $primary-color;
				text-transform: uppercase;
			}

		}

	}

}

#wrapper {
	overflow: hidden;
}

.container {
	max-width: 1170px;
	width: 100%;
}

.button,
._button,
._button2,
._button3,
a.woocommerce-button.button {
	display: inline-block;
	color: #fff;
	background-color: $primary-color;
	font-family: $font-family;
	box-shadow: none;
	height: inherit;
	text-align: center;
	text-decoration: none;
	font-size: 15px;
	font-weight: 600;
	line-height: 30px;
	transition: ease all 0.2s;
	text-transform: none;
	border-radius: 0px;

	&:hover,
	&:focus {
		background-color: $secondary-color;
		color: $white-color;
		text-decoration: none;
	}

}

.woocommerce {
	a.button {
		padding: 10px 20px !important;
	}

}

._button2,
a.woocommerce-button.button {
	padding: 2px 20px;
	font-size: 15px;
	font-weight: 600;
	background-color: $secondary-color;
	border: 1px solid $white-color;

	i {
		margin-right: 5px;
	}

	&:hover {
		background-color: $primary-color;
	}

}

._button3 {
	background-color: $primary-color;
}

.btn.primary-btn {
	background-color: $primary-color;
}

.btn.secondary-btn {
	background-color: $secondary-color;
}

#left {
	border-color: red;

	.handle {
		background-color: red;
	}

}

#right {
	border-color: orange;
	border-width: 5px;

	.handle {
		background-color: orange;
	}

}

#bottom {
	border-color: blue;

	.handle {
		background-color: blue;
	}

}

pre {
	margin-left: 3em;
}

.unique-button {
	position: relative !important;
	right: 0px !important;
	text-indent: inherit !important;
	background-color: transparent;
	padding: 0px !important;
}

.slide-out-div {
	padding: 30px;
	width: 340px;
	background: #3B3B3B;
	border: 0px;
	height: 350px;
	z-index: 9;

	.handle {
		background: rgba(0, 0, 0, 0) url("assets/images/calculator_close.jpg") no-repeat scroll 0% 0% !important;
	}

	.price-cal {
		.product-list {
			width: 100%;
			float: left;
			padding: 6px 8px;
			background: rgba(255, 255, 255, 0.4);
			border: 1px solid #fff;
			margin-bottom: 20px;

			select {
				width: 100%;
				font-size: 15px;
				height: 28px;
				color: black;
				font-weight: 600;
				border: 0px;
				padding: 0px 2px;
				-webkit-appearance: none;  /*Removes default chrome and safari style*/
				-moz-appearance: none;  /*Removes default style Firefox*/
				background: url("assets/images/down_arrow.jpg") no-repeat;
				background-position: 99.5% 1px;
				background-color: #fff;

				&:-moz-focusring {
					color: transparent;
					text-shadow: 0 0 0 #000;
				}

			}

			#p-attribute {
				float: left;
				width: 100%;
				color: #fff;
				font-size: 13px;
				font-weight: 700;
				padding-top: 8px;
				padding-bottom: 2px;
			}

		}

		.space-box {
			width: 100%;
			float: left;
			margin-bottom: 23px;

			span {
				color: #fff;
				font-size: 13px;
				float: left;
				line-height: 26px;
				font-weight: 700;
			}

			input[type="text"] {
				float: right;
				width: 80px;
				height: 28px;
				padding: 3px;
				border: 1px solid #000 !important;
				color: black !important;
				font-size: 14px;
			}

		}

		.cal-button {
			float: left;
			width: 100%;

			button {
				width: 100%;
				height: 30px;
				text-align: center;
				background-color: #007abf;
				color: #fff;
				border: 1px solid #fff;
				text-transform: uppercase;
				font-size: 15px;
				font-weight: 700;
			}

		}

		.result-items {
			float: left;
			width: 100%;
			color: #fff;
			margin-top: 20px;
			font-size: 14px;

			ul {
				margin: 0;
				font-size: 13px;
				line-height: 23px;

				li {
					list-style: none;
					float: left;
					width: 80%;

					&:first-child {
						width: 20%;
					}

					a {
						color: #fff;
						text-decoration: underline;
						font-weight: normal;
					}

				}

			}

		}

	}

}

.cal-single-button {
	margin-top: 30px;
}

@media screen and (min-width:481px) and (max-width:767px) {
	.slide-out-div {
       top: 140px !important;
       height: auto !important;
	}
}

@media screen and (max-width:480px) {
	.slide-out-div {
		width: 270px;
		top: 125px !important;
		z-index: 99;
		height: auto !important;
		padding: 18px 30px 19px 30px;

		.price-cal {

		    .result-items{
		    margin-top: 5px;
		    }
			.product-list {
			    margin-bottom: 10px;

				select {
					font-size: 13px;
				}

				#p-attribute {
					font-size: 11px;
				}

			}

			.space-box {
			    margin-bottom: 10px;

				span {
					font-size: 11px;
				}

				input[type="text"] {
					width: 52px;
				}

			}

		}

	}

}
