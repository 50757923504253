// Main CSS
$font-family: "Open Sans", sans-serif;
$font-size: 16px;
$font-weight: 300;

$primary-color: #007abf; /* Blue */
$secondary-color: #303030; /* Black */
$tertiary-color: #f9f9f9; /* Grey */
$white-color: #ffffff;

$transition: ease all 200ms;

// Overrule path for Font Awesome Fonts
$fa-font-path:    "assets/fonts/";
