@import '../../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
@import "_variables";
@import "../../node_modules/font-awesome/scss/font-awesome";
@import "_components";
@import "_checkout";
@import "_forms";
@import "_woo-gallery";
@import "_account";
@import "_cart";
@import "_checkout";
@import "_default";

// Header

.cookie-bar {
	background-color: rgb(249, 249, 249);
	padding: 20px;

	button._button, a._button {
		padding: 5px 15px;
	}

	h2 {
		margin: 0 0 15px 0;
	}

	h4 {
		font-size: 14px;
	}
}

.header-wrapper-home {
	height: 205px;
}

.header-wrapper {
	height: 230px;
}

.amount-validation.validation-error,
.antal-validation.validation-error {
    color: red;
    font-size: 13px;
    padding-bottom: 3px;
    display: block;
}

header#main {
	background: #ffffff;
	background: linear-gradient(to bottom, #ffffff 1%, #ffffff 29%, #f2f2f2 100%);
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 101;
	transition: all 0.2s ease;

	.usps {
		background: #272727;
		background: linear-gradient(to bottom, #272727 0%, #232222 100%);

		ul {
			list-style-type: none;
			margin: 0px;

			&:nth-child(2) {
	          li {
	         	text-algn: center;
	             }

               }
           &:nth-child(3) {
	          li {
	         	text-algn: right;
	             }

               }

			li {
				float: left;
				width: 33.333%;
				font-size: 14px;
				color: #d5d3d3;
				float: left;
				padding: 7px 15px;
				font-weight: 400;

				i {
					margin-right: 10px;
					color: $white-color;
				}

			}

		}



	}

	.infomenu {
		background-color: $tertiary-color;

		.menu-info-menu-container {
			float: left;
		}

		ul {
			float: right;
			list-style-type: none;
			margin: 0px;

			li {
				float: left;

				a {
					font-size: 15px;
					color: $secondary-color;
					border-left: 1px solid #dddddd;
					float: left;
					padding: 5px 15px;
					font-weight: 400;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}

					&.facebook {
						color: $white-color;
						background-color: #c3c3c3;

						&:hover {
							background-color: $primary-color;
						}

					}

				}

			}

		}

	}

	.zoeken {
		float: left;
		position: relative;
		z-index: 2;

		label {
			display: none;
		}

		input {
			height: 36px;
			border: 1px solid #c3c3c3;
			border-radius: 0;
			outline: 0;
			background-color: $white-color;
			width: 100%;
			padding: 0 0 0 15px;
			margin: 30px 15px 0 0;
			float: right;
			max-width: 220px;
			font-size: 14px;
		}

		input[type=submit] {
			width: 40px;
			height: 36px;
			background-color: #EC3A0D;
			border-left: 2px solid #fff;
			position: absolute;
			display: inline-block;
			right: 0;
			top: 0;
			text-indent: -9999px;
			color: #fff;
			background-color: $secondary-color;
			background-image: url(assets/images/search-icon.png);
			background-position: center center;
			background-repeat: no-repeat;

			&:hover {
				background-color: $primary-color;
			}

		}

	}

	#telnumber {
		display: inline-block;
		margin-top: 31px;
		margin-left: 20px;
		float: left;
	}

	#logo-bv-mobile {
		display: none;
	}

	#logo-bv {
		display: inline-block;
	}

	.logo-head-right {
        text-align: right;
		padding-left: 0px;
		width: 195px;
		float: right;
    }

	.logo-head-right{
	max-width: 100%;
    padding-top: 7px;

    img{
      padding: 8px 0px 0px;
	  max-width: 229px;
	  position: relative;
	  right: 28px;
    }

    }

	.menu-s {
    padding-right: 0px;
    }

	/* CART TOP */
	#shopping-cart {
		display: block;
		max-width: 100%;
		float: left;
		margin-top: 30px;
		margin-left: 32px;

		#cart-price {
			background-color: $secondary-color;
			color: #fff;
			padding: 9px 8px;
			float: left;
			margin-left: 2px;
			font-size: 13px;
		}

		#cart-name {
			background-color: $primary-color;
			text-transform: uppercase;
			color: #fff;
			padding: 7px 14px 7px 11px;
			float: left;
			margin-left: 2px;
			font-weight: 600;
			font-size: 16px;
		}

		#cart-number {
			background-color: $secondary-color;
			color: #fff;
			padding: 9px 14px;
			float: left;
			margin-left: 2px;
			font-weight: 600;
			font-size: 13px;
		}

	}

	.account-link {
        background-color: #303030;
        width: 30px;
        height: 30px;
        position: absolute;
        top: -51px;
        right: 152px;
        }


	.telmob {
		background-color: $secondary-color;
		width: 30px;
		height: 30px;
		position: absolute;
		top: -46px;
		right: 60px;

		i {
			color: $white-color;
			padding: 7px 5px 7px 9px;
		}

	}

	.shopmob {
		background-color: $secondary-color;
		width: 30px;
		height: 30px;
		position: absolute;
		top: -46px;
		right: 106px;

		i {
			color: $white-color;
			padding: 7px 5px 7px 7px;
		}

	}

	#logo img {
		padding: 10px 0;
		position: relative;
		z-index: 3;
		max-width: 290px;
	}

	/* ================================
	=Navigation
	================================ */
	.navbar-collapse {
		padding: 0;
	}

	.open .navbar-default {
		transition: $transition;
	}

	.navbar-default {
		background: #148cd1;
		background: linear-gradient(to bottom, #148cd1 0%, #157db8 100%);
		height: 46px;
		line-height: 44px;
		text-align: left;
		border-top: 1px solid $white-color;
		border-bottom: 1px solid $white-color;
		margin-bottom: 0px;
		transition: $transition;

		.navbar-nav {
			margin: 0 auto;
			line-height: 36px;
			float: left;
			transition: $transition;

			li,
			li:first-child {
				padding: 0px;
				margin-right: 15px;
				font-weight: 600;
				color: #fff;
				font-size: 14px;
				text-transform: uppercase;
				border-bottom: 3px solid transparent;

				a {
					padding: 0 15px;
					line-height: 48px;
					display: inline-block;
					color: #fff;
					border-left: 1px solid transparent;
					border-right: 1px solid transparent;
				}

				&:hover a,
				&.active a,
				&:focus a {
					background-color: $secondary-color;
					color: $white-color;
					padding: 0 15px;
					line-height: 48px;
					text-decoration: none;
					border-left: 1px solid white;
					border-right: 1px solid white;
				}

			}

			li:first-child {
				font-size: 0px;
				background-color: transparent;

				a {
					font-size: 0px;
					line-height: 48px;
					display: inline-block;
					padding: 0 15px 0 10px;

					&:before {
						background-color: $secondary-color;
						color: $white-color;
						padding: 0 15px 0 0;
						line-height: 48px;
						text-decoration: none;
						content: "\f015";
						background-color: transparent;
						font-family: fontawesome;
						font-size: 16px;
						left: 10px;
						color: #fff;
						display: inline-block;
						position: relative;
						z-index: 1;
					}

				}

				&:hover a,
				&.active a {
					padding: 0 15px 0 10px;
					line-height: 36px;

					&:before {
						background-color: $secondary-color;
						color: $white-color;
						line-height: 48px;
						text-decoration: none;
						content: "\f015";
						background-color: transparent;
						font-family: fontawesome;
						font-size: 16px;
						color: #fff;
						display: inline-block;
						position: relative;
						z-index: 1;
					}

				}

			}

		}

	}

	// Navbar toggle

	.navbar-default .navbar-toggle {
		border: none;
	}

	.navbar-default .navbar-toggle:hover,
	.navbar-default .navbar-toggle:focus {
		background: none;
	}

	.navbar-toggle {
		position: absolute;
		right: 0px;
		padding: 15px 15px;
		border-radius: 0px;
		background-color: #007abf !important;
		background-image: none;
		border: 1px solid transparent;

		// styles to `:focus`.
		&:focus {
			outline: 0;
		}

		// Bars
		.icon-bar {
			position: absolute;
			display: block;
			background-color: #fff;
			top: 5px;
			right: 6px;
			width: 17px;
			height: 2px;
			border-radius: 1px;
			transition: all ease-in-out 0.3s;
		}

		.icon-bar:nth-child(2) {
			margin-top: 4px;
		}

		.icon-bar:nth-child(3) {
			margin-top: 9px;
		}

		.icon-bar:nth-child(4) {
			margin-top: 14px;
		}

	}

	body.nav-open {
		.navbar-toggle {
			.icon-bar {
				transition: all ease-in-out 0.3s;
			}

			.icon-bar:nth-child(2) {
				background-color: transparent;
			}

			.icon-bar:nth-child(3) {
				top: 8px;
				transform: rotate(45deg);
			}

			.icon-bar:nth-child(4) {
				top: 2px;
				transform: rotate(-45deg);
			}

		}

	}

	// Dropdown


	.breadcrumb {
		background-color: $tertiary-color;
		border-radius: 0px;
		margin-bottom: 0px;

		span {
			font-size: 14px;
			font-weight: 400;
		}

		a {
			color: $primary-color;
			font-size: 14px;
			font-weight: 700;
			text-decoration: none;

			&.active {
				color: $primary-color;
				font-weight: bold;
			}

			&:hover {
				text-decoration: underline;
			}

		}

		i {
			color: #646464;
			font-size: 14px;
			font-weight: 400;
		}

	}

	&.scrolled {
		box-shadow: 2px -13px 41px #000;

		.breadcrumbs {
			display: none;
		}

	}

}

/* Drop-down menu */
.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: -2px 0 0;
	list-style: none;
	font-size: 14px;
	text-align: left;
	background-color: #fff;
	border: none;
	box-shadow: none;
	border-radius: 4px;
	background-clip: padding-box;

	a {
		margin: 0px;
	}

}

/* Ambience photo */
div#ambience-photo,
div#ambience-photo2 {
	background-size: cover;
	background-position: center;
	width: 100%;
	display: table;
	height: 250px;
	text-align: center;
	position: relative;
}

div#ambience-photo2 {
	height: 300px;
}

div#notfound-headpic {
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	display: table;
	height: 250px;
	text-align: center;
	position: relative;
	background-size: contain;
}

/* Home content */
section.shadowbox,
section.content,
section.notfound {
	position: relative;
	margin-bottom: 30px;

	.container {
		background-color: $white-color;
		padding: 30px 30px 0px 30px;
		z-index: 2;
		box-shadow: 0px 0px 8px #919191;
		text-align: center;
		margin-top: -65px;

		h1 {
			color: $primary-color;
			font-size: 34px;
		}

		.home-category {
			width: 100%;
			display: block;
			height: 310px;
			position: relative;
			text-align: center;
			margin-bottom: 30px;

			p {
				background: #232323;
				background: linear-gradient(to bottom, #232323 2%, #3e3e3e 100%);
				position: absolute;
				bottom: 0px;
				width: 100%;
				border-top: 1px solid $white-color;
				font-size: 18px;
				color: $white-color;
				font-weight: 700;
				padding: 20px 0;
				margin: 0px;
			}

			&:hover p {
				background: #575757;
				background: linear-gradient(to bottom, #575757 0%, #6c6c6c 100%);
			}

		}

		.contact-info {
			background: #232323;
			background: linear-gradient(to bottom, #232323 2%, #3e3e3e 100%);
			padding: 83px 30px;

			h3 {
				margin: 0px 0 20px 0;
				color: $white-color;
				font-size: 30px;
			}

			p {
				color: $white-color;
				font-weight: 600;
				font-size: 18px;

				a {
					color: $white-color;
					font-weight: 600;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}

				}

				i {
					margin-right: 10px;
				}

			}

		}

	}

}

section.content,
section.notfound {
	.container {
		.contact-info {
			padding: 50px 24px;

			h3 {
				margin: 0px 0 20px 0;
				font-size: 22px;
			}

			p {
				font-size: 16px;
				margin: 10px 0;
			}

		}

	}

}

/* Content page */
section.content,
section.notfound {
	position: relative;
	margin-bottom: 30px;

	.container {
		background-color: $white-color;
		padding: 40px 40px 30px 40px;
		z-index: 2;
		box-shadow: 0px 0px 8px #919191;
		text-align: left;
		margin-top: -160px;

		h1 {
			color: $primary-color;
			font-size: 22px;
			text-align: left;
			margin: 0 0 20px 0;
		}

	}

}

section.notfound {
	.container {
		margin-top: -30px;
	}

}

/* Sidebar news */
.sidebar-news {
	width: 100%;
	background-size: cover;
	height: 180px;
	float: left;
	position: relative;
	text-decoration: none;
	text-align: left;
	z-index: 0;
	background-position: center center;
	margin-bottom: 40px;

	p {
		background: url(../images/sidebar-news2.png);
		background-repeat: repeat-x;
		padding: 10px 10px 10px 15px;
		line-height: 22px;
		font-size: 14px;
		font-weight: 600;
		color: #fff;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		z-index: 2;
		margin: 0px;
	}

}

/* Page */
section.page {
	background-color: $white-color;
	position: relative;
	margin-bottom: 30px;
	padding-top: 40px;
	text-align: left;
	padding-bottom: 30px;

	h1 {
		color: $primary-color;
		font-size: 22px;
		text-align: left;
		margin: 0 0 20px 0;
	}

	.introtext {
		background-color: $tertiary-color;
		padding: 20px;
		height: 100%;
		width: 100%;
		float: left;
	}

	.product,
	.product2 {
		float: left;
		width: 100%;
		border: 2px solid $primary-color;
		text-decoration: none;
		margin-top: 30px;

		.title,
		.title2 {
			height: 67px;
			background-color: $primary-color;
			color: $white-color;
			font-size: 16px;
			font-weight: bold;
			line-height: 22px;
			padding: 10px 20px;
			margin: 0px;
		}

		.title2 {
			height: 45px;
		}

		.image,
		.image2 {
			border-bottom: 2px solid $primary-color;
			width: 100%;
			margin: 0px;

			img {
				height: 150px;
				object-fit: contain;
			}

		}

		.image2 {
			border: none;
		}

		.info {
			padding: 10px 20px;
			margin: 0px;
			height: 160px;
		}

		.button {
			background-color: #c2e9ff;
			text-align: center;
			width: 100%;
			font-size: 16px;
			font-weight: 600;
			padding: 10px 20px;
			border-top: 2px solid $primary-color;
			margin: 0px;
			color: $primary-color;
		}

		&:hover {
			.button {
				background-color: $white-color;
			}

		}

	}

	.product2 {
		.image,
		.image2 {
			border-bottom: none;
		}

	}

	.product-side {
		border: 2px solid $primary-color;
	}

	.productspecs.ev-cat{
	.titlebar{
	p{
	width: 12.6%;
	}
	}
	}

	.productspecs.ev-cat{
	.infobar-uneven{
	p{
	width: 12.5%;
	}
	}
	}

	.productspecs.cat-support{
	.titlebar.large{
	p {
    width: 45.6%;
    }
	}
    .infobar-uneven.large{
    p {
    width: 30%;
    &:nth-child(4){
    width:15%;
    }
    }
    }
	}

	.productspecs {
		width: 100%;
		float: left;
		border: 2px solid $primary-color;
		margin-top: 30px;

		.titlebar,
		.titlebar.large,
		.titlebar.alt {
			background: #148cd1;
			background: linear-gradient(to bottom, #148cd1 0%, #157db8 100%);
			width: 100%;
			float: left;
			padding: 0 15px;

			p {
				color: $white-color;
				font-size: 14px;
				font-weight: bold;
				float: left;
				padding: 5px 0px;
				margin: 0px;
				width: 11.4%;
			}

		}

		.titlebar.large {
			p {
				width: 45.6%;
			}

		}

		.titlebar.alt {
			p {
				width: 22%;
			}

		}

		.infobar-even,
		.infobar-uneven,
		.infobar-uneven.large,
		.infobar-uneven.alt {
			background-color: $white-color;
			width: 100%;
			float: left;
			padding: 0 0 0 15px;
			border-bottom: 1px solid #c2e9ff;

			&:last-child {
				border-bottom: none;
			}

			&:hover {
				background-color: #c2e9ff;
			}

			p {
				font-size: 14px;
				font-weight: 400;
				float: left;
				padding: 5px 0px;
				margin: 0px;
				width: 11.25%;
				text-decoration: none;

				&.title {
					color: $primary-color;
					text-decoration: underline;
				}

				&.button {
					width: auto;
					float: right;
					padding: 3px 12px;
					background-color: #c2e9ff;
					font-weight: bold;
					color: $primary-color;
				}

			}

		}

		.infobar-uneven.large {
			p {
				width: 45%;
			}

		}

		.infobar-uneven.alt {
			p {
				width: 22%;
			}

		}

		.infobar-even {
			background-color: $tertiary-color;

			p {
				&.button {
					background-color: #adddfa;
				}

			}

		}

	}

	.gallery,
	.gallery2 {
		float: left;

		.picture {
			border: 2px solid $primary-color;
			position: relative;
			display: inline-block;
		}

		.thumbs {
			.thumb {
				border: 2px solid #007abf;
				float: left;
				width: 34%;
				height: 55px;
				margin: -2px 0 0 -2px;

				&:first-child {
					margin-left: 0px;
					width: 33.4%;
				}

			}

		}

		i {
			position: absolute;
			bottom: 0px;
			right: 0px;
			background-color: $primary-color;
			padding: 5px;
			color: $white-color;
		}

	}

	.woocommerce-product-gallery--columns-4 .flex-control-thumbs li {
		margin-top: -2px;
	}

	.flex-control-nav.flex-control-thumbs {
		margin: 0;
	}

	.flex-viewport {
		border: 2px solid #007abf;
	}

	.woocommerce-product-gallery .flex-control-thumbs img.flex-active,
	.woocommerce-product-gallery .flex-control-thumbs img:hover {
		border: 2px solid #007abf;
	}

	.woocommerce-product-gallery .flex-control-thumbs img {
		border: 2px solid #007abf;
	}

	.gallery-text,
	.gallery-text2 {
		background-color: $tertiary-color;
		padding: 20px;
		height: 100%;
		width: 100%;
		float: left;
	}

	.gallery-text2 {
		margin-bottom: 30px;
	}

	.table2 .stock.in-stock {
    display: none;
    }

    .table2 .input-text.qty.text {
    visibility: hidden;
    }

	.table-info {
		float: left;
		margin-bottom: 30px;

		.title {
			background: #148cd1;
			background: linear-gradient(to bottom, #148cd1 0%, #157db8 100%);
			width: 100%;
			float: left;
			padding: 10px 15px;
			margin: 0px;
			color: $white-color;
			font-size: 18px;
			font-weight: 600;
		}

		a {
			background-color: #737373;
			font-size: 14px;
			color: $white-color;
			text-decoration: none;
			padding: 5px 15px;
			margin: 10px auto 15px -15px;
			display: inline-block;

			&:hover {
				background-color: $primary-color;
			}

		}

		.even,
		.uneven,
		.uneven-line {
			background-color: $tertiary-color;
			width: 100%;
			float: left;
			padding: 3px 0 3px 15px;
			margin: 0px;

			span {
				width: 50%;
				float: right;
				font-weight: bold;

				&.right {
					text-align: right;
					padding-right: 15px;
				}

			}

		}

		.even {
			background-color: #eaf3f9;

			p {
				&.button {
					background-color: #adddfa;
				}

			}

		}

		.uneven-line {
			border-bottom: 1px solid #767474;
			color: #767474;
			font-weight: bold;
		}

		form {
			width: 100%;
			padding: 15px;
			background-color: $tertiary-color;
			float: left;

			.quantity {
				width: 60%;
				margin: 0;
				padding: 0;
				display: inline;
			}

			input[type="submit"]:disabled{
			background-color: #d3d3d3;

			&:hover{
			background-color: #d3d3d3;
			}
			}

			.formrow {
				margin-bottom: 5px;

				.tooltip-inner {
					min-width: 100px;
					padding: 3px 5px;
					color: #fff;
					text-align: center;
					background-color: #303030;
					border-radius: 0px;
				}

				.tooltip-arrow {
					border-right-color: #303030;
				}

				.info-orb {
					float: left;
					margin: 0px;
					padding: 0px;
					width: auto;
					background: none;
					cursor: pointer;

					i {
						float: right;
						color: #cbcbcb;
						margin: 4px 0px 0 10px;
						font-size: 16px;
					}

					&:hover i {
						color: $primary-color;
					}

				}

				label {
					width: 40%;
					color: #767474;

					&.required {
						color: $primary-color;
					}

				}

				#amount.borderclass,
				#antal.borderclass {
					border: 1px solid red !important;
				}

				input,
				select {
					width: 40%;
					float: right;
					border: 1px solid #767474;
					font-size: 14px;
					padding-left: 5px;
					margin-top: 2px;

					&.required,
					&:focus {
						border: 1px solid $primary-color;
						color: $primary-color;
					}

				}

			}

			#dproduct.formrow {
			width: 100%;
			}

			input[type=submit] {
				background-color: #737373;
				font-size: 14px;
				color: $white-color;
				text-decoration: none;
				display: inline-block;
				font-weight: 600;
				padding: 5px 15px;
				margin: 10px auto 0 auto;
				left: 25%;
				position: relative;
				width: auto;

				&:hover {
					background-color: $primary-color;
				}

			}

			#btn-toggle {
				background-color: #737373;
				font-size: 14px;
				color: $white-color;
				text-decoration: none;
				display: inline-block;
				font-weight: 600;
				padding: 5px 15px;
				margin: 10px auto 0 auto;
				left: 25%;
				position: relative;
				width: auto;
			}

		}

		.pricebox {
			p {
				margin: 0px;
				width: 100%;

				span.woocommerce-Price-amount.amount {
					line-height: 42px;
					font-weight: bold;
					color: #767474;
					font-size: 20px;
				}

				span.price-tax {
					font-size: 14px;
					margin-left: 10px;
					font-weight: bold;
				}

				&.blue {
					color: $primary-color;
					border-top: 1px solid #767474;
				}

				&.check,
				&.normal {
					font-size: 15px;
					line-height: 28px;
					font-weight: 400;
					color: #474848;

					i {
						color: #27b90a;
						font-size: 18px;
					}

				}

				a {
					background-color: #737373;
					font-size: 14px;
					color: #ffffff;
					text-decoration: none;
					padding: 0px 15px;
					margin: 10px auto 15px auto;
					display: inline-block;

					&:hover {
						background-color: $primary-color;
					}

				}

			}

		}

	}

	.info-block {
		background-color: #f9f9f9;
		width: 100%;
		padding: 20px;

		p {
			font-weight: bold;
			margin-bottom: 0px;
		}

		a,
		a.blue-btn {
			background-color: #737373;
			font-size: 14px;
			color: #ffffff;
			text-decoration: none;
			padding: 5px 12px;
			margin: 10px 10px 0 0;
			display: inline-block;

			&:hover {
				background-color: #007abf;
			}

		}
	}

	/* NEWS */
	ul.newsitem {
		margin: 0;
		float: left;
		width: 100%;

		li {
			list-style-type: none;
			float: left;
			background-color: #f9f9f9;
			padding: 15px 15px 15px 15px;
			width: 100%;
			margin-bottom: 30px;

			a.news-image {
				float: left;
				width: 100%;
				height: 200px;
				display: block;
				overflow: hidden;
				position: relative;

				img {
					height: 100%;
					object-fit: cover;
					position: relative;
					z-index: 0;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					transition: ease 500ms;
					-webkit-backface-visibility: hidden;
				}

				&:hover {
					img {
						transform: scale(1.2, 1.2);
					}

				}

			}

			h3 {
				font-size: 18px;
				margin: 15px 0 10px 0;
				display: block;
				float: left;
				width: 100%;
				height: 45px;
				line-height: 22px;
			}

			p {
				font-size: 14px;
				line-height: 22px;
				height: 45px;
				float: left;
				width: 100%;
				margin: 0 0 10px 0;
			}

			.btn-news {
				margin-top: 3px;
				font-size: 14px;
				display: block;
			}

		}

	}

	/* SEARCH RESULTS */
	ul.searchresults {
		margin: 0;

		a.search-image {
			float: left;
			margin-right: 15px;
		}

		li {
			list-style-type: none;

			h3 {
				font-size: 18px;
				margin-bottom: 10px;
			}

			p {
				font-size: 16px;
				margin: 0;
			}

			img {
				max-width: 120px;
			}

			.search-readmore {
				margin-top: 10px;
				font-size: 14px;
			}

		}

	}

	/* SHOPPING CART */
	.titlewrap {
		padding: 0px;
		float: left;

		.titlebar {
			background-color: #d4d3d3;
			font-weight: bold;
			padding: 20px 30px;
			float: left;
			width: 100%;

			.product-title,
			.amount-title,
			.price-title,
			.sub-title,
			.weight-title,
			.total-weight-title {
				font-weight: 700;
			}

		}

		.infobar {
			padding: 10px 30px 10px 30px !important;
			float: left;
			width: 100%;
			border-bottom: 1px solid #e0e0e0;
			background-color: $tertiary-color;

			form {
				float: left;
				display: inline-block;
			}

			input[type=text] {
				height: 30px;
				line-height: 30px;
				width: 100px;
				border: 1px solid #c3c3c3;
				float: left;
				display: inline-block;
				background-color: $white-color;
				margin-right: 3px;
				margin-top: 16px;
			}

			input[type=submit] {
				background-color: $secondary-color;
				font-size: 13px;
				float: left;
				display: inline-block;
				font-weight: bold;
				color: $white-color;
				padding: 0px 15px 0px 15px;
				line-height: 30px;
				margin-top: 16px;
			}

		}

		.shop-image {
			width: 25%;
			background-color: $white-color;
			border: 1px solid $primary-color;
		}

		.product-title,
		.amount-title,
		.price-title,
		.sub-title,
		.weight-title,
		.total-weight-title {
			display: inline-block;
			line-height: 20px;
			margin: 0px;
			padding: 0px;
			font-weight: 400;
			font-size: 16px;
			float: left;
		}

		.product-title {
			width: 30%;
		}

		.amount-title {
			width: 20%;
			float: left;

			form {
				input[type=text] {
					height: 20px;
					width: 100px;
					border: 1px solid #c3c3c3;
				}

			}

		}

		.price-title,
		.weight-title,
		.total-weight-title {
			width: 12%;
		}

		.product-title {
			span {
				margin-left: 10px;
			}

		}

		.sub-title {
			width: 12%;
		}

		.product-info {
			line-height: 73px;
			margin-top: 0px;
			font-weight: 300;
			float: left;

			a {
				font-size: 22px;
				margin-left: 5px;
			}

		}

		.infobar {
			line-height: 60px;

			p {
				line-height: 60px;
			}

			form {
				line-height: 60px;
			}

		}

	}

	.text-right {
		text-align: right;
	}

	.bold {
		font-weight: 700;
	}

	.float-r {
		float: right;
		display: inline-block;
	}

	.cartline {
		border-bottom: 1px solid #e0e0e0;
		padding: 0 15px;
	}

	.cartline-last {
		border-bottom: 1px solid #e0e0e0;
		margin-bottom: 10px;
		padding: 0 15px;
	}

}

/* Footer */
footer {
	background: #222222;
	padding: 50px;
	width: 100%;
	top: -50px;
	text-align: left;

	.woocommerce-MyAccount-navigation {
		width: 100%;

		ul {
			padding: 0;

			li {
				margin-bottom: 5px;

				a {
					padding: 0;
				}

			}

		}

	}

	h4 {
		font-size: 19px;
		text-transform: uppercase;
		color: $white-color;
		font-weight: 400;
	}

	ul,
	.openingstijden {
		margin: 0px;

		span {
			width: 50px;
			float: left;
		}

		li {
			list-style-type: none;
			color: $white-color;
			margin: 0px;
			font-weight: 400;
			margin-bottom: 5px;

			a {
				color: $white-color;
				font-weight: 400;
				text-decoration: none;

				&:hover {
					color: $white-color;
					font-weight: 400;
					text-decoration: underline;
				}

			}

		}

	}

}

#price-open {
	display: none;
}

#btn-toggle {
	cursor: pointer;
}

.single_add_to_cart_button.button.alt {
	padding: 7px 20px;
	margin-top: 15px;
}

.gallery2 .woocommerce-product-gallery {
	margin-bottom: 0px;
	border: 2px solid $primary-color;
}

form {
	input[type="text"],
	input[type="email"],
	input[type="password"] {
		&:invalid:not(:focus):not(:placeholder-shown) {
			border: 1px solid #d91015 !important;
			color: #d91015 !important;
		}

		&:invalid:focus:not(:placeholder-shown) {
			& ~ .requirements {
				max-height: 200px;
				padding: 0 30px 20px 50px;
			}

		}

		&:optional:not(:valid) {
			border: 1px solid grey !important;
		}

		&:valid {
			border: 1px solid green !important;
			color: green !important;
		}

		&:optional {
			border: 1px solid grey !important;
		}

	}

}

.requirements {
	margin: 0 !important;
	padding: 0 !important;
	font-size: 12px;
	max-height: 0;
	transition: 0.28s;
	overflow: hidden;

	p {
		border: 1px solid #d91015;
		color: #d91015;
		padding: 5px 10px;
		width: 100%;
		float: left;
		font-size: 12px;
		margin: 5px 0;
	}

}
.totalweight{
	background: #f9f9f9;
    padding: 10px 20px 10px 20px;
}

.red_message{
  color:red;
  font-weight: 600;
}
.green_message{
  color:green;
  font-weight: 600;
}
.qty-increment{
  display: initial;
 .quantity {
    width: 109px;
    minus {
    float: left;
    }
    .plus {
    float: right;
    }
 }
}
.woocommerce{
.quantity{
.qty{
	width: 55px;
	text-align: center;
	-moz-appearance: textfield;
	border: 1px solid #007abf;
	font-size: 16px;
	height: 26px;
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button{
	-webkit-appearance: none;
    margin: 0;
	}
}
}
}
.btn-number{
  border-radius: 0px;
  background: #212121;
  padding:2px 7px !important;
  color:#fff;
  &:hover{
  	color:#fff;
  }
}
.pricebox {
.btn-number {
    display: none;
}
}
.shopping-cart{
	.shipping{
	  display: none;
	}
}

.padding0 table tr:nth-child(3) td label {
    font-weight: normal;
}

.padding0 table tr:nth-child(3) td span:first-child {
    margin-right: 8px;
}

.padding0 table input[type="radio"] {
    position: relative;
    top: 3px;
}
.cart_totals .woocommerce-Price-currencySymbol {
    padding-right: 5px;
}

.cart_totals td {
    text-align: right;
    padding-right: 110px !important;
}

.shop_table .cart-discount {
    display: none;
}

.shop_table .tax-rate {
    display: none;
}

table.checkout-wt {
    margin: 50px 0px 50px;
    background-color: #f9f9f9;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    border-collapse: separate;
    width: 100%;
    text-align: left;
}

.checkout-wt th {
    font-weight: 700;
    padding: 15px 12px;
}

.checkout-wt td {
padding: 6px 15px 6px 10px;
vertical-align: middle;
font-weight: 700;
text-align: right;
}

.woocommerce-checkout-review-order .cart_item {
    font-size: 15px;
}

#order_review .shop_table tr:first-child th:last-child {
    padding-left: 17px;
    text-align: left;
}

.woocommerce #order_review table.shop_table td.product-name {
    text-align: left;
    padding-right: 15px;
}
.woocommerce #order_review table.shop_table td {
    text-align: right;
    padding-right: 15px;
}

.woocommerce #order_review table.shop_table td span.woocommerce-Price-currencySymbol{
	padding-right: 5px;
}

.shop_table.woocommerce-checkout-review-order-table .shipping {
    display: none;
}

.woocommerce #order_review table.shop_table td.pickup-text{
	font-weight: 500;
	text-align: left;
	padding: 12px 15px;
}

.woocommerce-table.woocommerce-table--order-details.shop_table.order_details tr.order-shipping,
.woocommerce-table.woocommerce-table--order-details.shop_table.order_details tr.order-discount {
    display: none;
}

#postcode1{
  width:15%;
  padding:5px;
  font-size:14px;
  border: 1px solid;
}
#message,.distance_more_than_100,.postcode_not_found{
  color:#3484c7;
}
.distance_message,
.show-postcode,
.mounted_crane_option,
.distance_more_than_100{
  display: none;
}
.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
  text-decoration: none;
}
.woocommerce table.shop_table{
  margin-bottom:0px !important;
  border-top: none !important;
}
.row-height{
  height: 50px;
  background: #efe9e9;
}
.padding0{
  padding: 0px !important;
}
.empty-row-height{
  height: 100px;
}

table.thank-weight {
    margin-top: 45px;
    text-align: left;
    width: 100%;
    border-collapse: separate;
    border-radius: 0px;
    background-color: #f9f9f9;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.woocommerce-order-details .woocommerce-table td:first-child {
    width: 65%;
}

table.thank-weight th,
table.thank-weight td{
  font-weight: 700;
  padding: 15px 12px;
  width: 65%;
}

.woocommerce-order .col-1 address {
    border: 1px solid rgba(0,0,0,.1);
    padding: 10px;
}

.woocommerce-order .col-2 address {
    border: 1px solid rgba(0,0,0,.1);
    padding: 10px;
}
.woocommerce-order-details td.pickup-text,
.woocommerce-order-details th.pickup-text {
    font-weight: normal !important;
    height: 60px;
}

.woocommerce-order-details .woocommerce-table td:last-child,
.woocommerce-order-details .woocommerce-table th:last-child,
table.thank-weight td {
    text-align: right;
    padding-right: 15%;
}

.woocommerce-order-details .woocommerce-customer-details .woocommerce-table td:last-child{
  text-align: left;
  padding-right: 15px;
}
.woocommerce-order-details .woocommerce-table td.pickup-text:last-child,
.woocommerce-order-details .woocommerce-table th.pickup-text:last-child {
    text-align: left;
}
.woocommerce-order-details .woocommerce-table td:last-child span.woocommerce-Price-currencySymbol {
    padding-right: 5px;
}

@media screen and (max-width: 768px) {

	section.page {
		.product,
		.product2 {
			.info {
				height: auto;
			}
		}
	}

.woocommerce-order-details .woocommerce-table td:first-child {
    width: auto;
    text-align: right;
   }
   .woocommerce-order-details .woocommerce-table td:last-child, .woocommerce-order-details .woocommerce-table th:last-child, table.thank-weight td {
    padding-right: 15px;
  }

  .woocommerce-order-details .woocommerce-table tfoot tr.to_subtotal,
  .woocommerce-order-details .woocommerce-table tfoot tr.thank-ptup,
  .woocommerce-order-details .woocommerce-table tfoot tr.thank-trans,
  .woocommerce-order-details .woocommerce-table tfoot tr.deliverytexts,
  .woocommerce-order-details .woocommerce-table tfoot tr.thank-pt,
  .woocommerce-order-details .woocommerce-table tfoot tr.thank-teb,
  .woocommerce-order-details .woocommerce-table tfoot tr.thank-btw,
  .woocommerce-order-details .woocommerce-table tfoot tr.thank-orderitem-row,
  .woocommerce-order-details .woocommerce-table tfoot tr.thank-total,
  .woocommerce-order-details .woocommerce-customer-details table.woocommerce-table tr{
   width: 100%;
   float: left;
   border-top: 1px solid rgba(0,0,0,.1);
   }
  .woocommerce-order-details .woocommerce-table tfoot tr.to_subtotal th,
  .woocommerce-order-details .woocommerce-table tfoot tr.thank-ptup th,
  .woocommerce-order-details .woocommerce-table tfoot tr.thank-pt th,
  .woocommerce-order-details .woocommerce-table tfoot tr.thank-teb th,
  .woocommerce-order-details .woocommerce-table tfoot tr.thank-btw th,
  .woocommerce-order-details .woocommerce-table tfoot tr.thank-orderitem-row th,
  .woocommerce-order-details .woocommerce-table tfoot tr.thank-total th,
  .woocommerce-order-details .woocommerce-customer-details .woocommerce-table th,
  .woocommerce-order-details .woocommerce-table tfoot tr.thank-trans th{
   display: inherit;
   float: left;
   width: 50%;
   }
   .woocommerce-order-details .woocommerce-table tfoot tr.to_subtotal td,
   .woocommerce-order-details .woocommerce-table tfoot tr.thank-ptup td,
   .woocommerce-order-details .woocommerce-table tfoot tr.thank-pt td,
   .woocommerce-order-details .woocommerce-table tfoot tr.thank-teb td,
   .woocommerce-order-details .woocommerce-table tfoot tr.thank-btw td,
   .woocommerce-order-details .woocommerce-table tfoot tr.thank-orderitem-row td,
   .woocommerce-order-details .woocommerce-table tfoot tr.thank-total td,
   .woocommerce-order-details .woocommerce-customer-details .woocommerce-table td,
   .woocommerce-order-details .woocommerce-table tfoot tr.thank-trans td{
   float: right;
   width: 50% !important;
   padding-left: 15px !important;
   padding-top: 18px;
   }
   .woocommerce-order-details td.pickup-text, .woocommerce-order-details th.pickup-text {
    font-weight: normal !important;
    height: auto;
    }
   .woocommerce-order-details .woocommerce-table tfoot tr.thank-pt th {
    text-align: left;
    font-weight: normal;
   }
   .woocommerce-order-details .woocommerce-customer-details table.woocommerce-table{
    float: left;
    width: 100%;
   }

 .cart_totals td {
    text-align: right;
    padding-right: 15px !important;
    }
 .cart_totals .shipping {
    display: none !important;
    }
  .distance_message, .show-postcode, .mounted_crane_option, .distance_more_than_100 {
    display: none;
  }
  .woocommerce table.shop_table td.distance_message, .woocommerce table.shop_table td.mounted_crane_option,
  .woocommerce table.shop_table td.distance_more_than_100 {
    display: none;
  }
  #postcode1 {
    width: 93px;
  }
  .col-md-6.padding0.cart-emmpty td::before {
    content: none;
  }
  .col-md-6.padding0.cart-emmpty td {
    padding-left: 15px !important;
    text-align: left !important;
  }
  .row-height.b-row {
    display: none !important;
  }
  .shop_table.shop_table_responsive.cart.woocommerce-cart-form__contents td.cempty-text:before{
    content: none;
  }
  .shop_table.shop_table_responsive.cart.woocommerce-cart-form__contents td.cempty-text{
    padding-left: 15px !important;
    text-align: left !important;
  }
  .product-remove.remove {
    display: none !important;
  }
  .cart_totals tr.tax-rate {
    display: none !important;
  }
  .shop_table.woocommerce-checkout-review-order-table tr.tax-rate {
    display: none;
  }
  .woocommerce table.shop_table td.pickup-text {
    padding-left: 15px !important;
  }
  .woocommerce #order_review table.shop_table td.product-name {
    text-align: right;
  }
  #order_review .shop_table tr.cart-subtotal,
  #order_review .shop_table tr.totalb,
  #order_review .shop_table tr.order-total,
  #order_review .shop_table tr.totaleb,
  #order_review .shop_table tr.tcost,
  #order_review .shop_table tr.deliverytexts,
  #order_review .shop_table tr.pickuptexts {
    width: 100%;
    float: left;
  }
  #order_review .shop_table tr.cart-subtotal th,
  #order_review .shop_table tr.totalb th,
  #order_review .shop_table tr.order-total th,
  #order_review .shop_table tr.totaleb th,
  #order_review .shop_table tr.tcost th {
    display: inherit;
    float: left;
    width: 50%;
  }
  #order_review .shop_table tr.cart-subtotal td,
  #order_review .shop_table tr.totalb td,
  #order_review .shop_table tr.order-total td,
  #order_review .shop_table tr.totaleb td,
  #order_review .shop_table tr.tcost td {
    float: right;
    width: 50% !important;
    padding-left: 15px !important;
    padding-top: 18px;
  }

  #order_review .shop_table tr.cart-discount {
    display: none;
  }
  #ship-to-different-address label {
    font-size: 15px;
  }
}

@import "_responsive.scss";
