@media screen and (max-width: 1024px) {
    header#main {
        nav#nav-main {
            ul {
                li {
                    a {
                        font-size: 14px;
                        margin: 0px;
                    }
                }
            }
        }
    }

    /* Ambience photo */

    div#ambience-photo, div#ambience-photo2, div#notfound-headpic {
        background-size: cover;
        height: 300px;
        margin-top: 0px;
    }

}

@media only screen and (min-width: 992px) and (max-width: 1169px){
   header#main .logo-head {
    width: 20%;
   }
   header#main #logo img{
    max-width: 100%;
   }
   header#main .menu-s {
    padding-right: 0px;
    padding-left: 0px;
    }
    header#main .zoeken input{
      max-width: 190px;
      margin: 30px 15px 0 0;
    }
    header#main #telnumber{
      margin-top: 31px;
      margin-left: 5px;
    }
    header#main #telnumber ._button2{
      font-size: 14px;
    }
    header#main #shopping-cart{
      margin-left: 17px;
    }
    header#main .logo-head-right{
      width: 170px;
    }
    header#main .logo-head-right img{
      max-width: 100%;
    }
}

@media only screen and (min-width: 769px) and (max-width: 991px){
    header#main .logo-head {
     width: 25%;
     float: left;
    }
    header#main .menu-s {
    padding-right: 0px;
    width: 48%;
    }
    header#main .zoeken input{
      margin: 23px 15px 0 0;
    }
    header#main .account-link{
      top: 25px;
      right: 102px;
    }
    header#main .telmob{
      top: 25px;
      right: 15px;
    }
    header#main .shopmob{
      top: 25px;
      right: 58px;
    }
    header#main .logo-head-right {
    width: 195px;
   }
   header#main #logo img{
     max-width: 170px;
   }
   header#main .logo-head-right img {
    max-width: 170px;
    right: 0;
   }
   .header-wrapper-home {
    height: 130px;
   }
   header#main .navbar-default .navbar-nav li, header#main .navbar-default .navbar-nav li:first-child{
    margin-right: 5px;
   }
   header#main .navbar-default .navbar-nav li a, header#main .navbar-default .navbar-nav li:first-child a, header#main .navbar-default .navbar-nav li:hover a{
    padding: 0 8px;
    font-size: 13px;
   }
  .slide-out-div{
    top: 185px !important;
  }
  section.shadowbox .container .home-category, section.content .container .home-category, section.notfound .container .home-category{
    background-size: cover;
   }
}

@media screen and (max-width: 768px) {

   .slide-out-div{
     top: 170px !important;
    }
    .eq-height {
        display: inline-block;
    }

    header#main {
      #logo-bv-mobile {
        display: block;
      }

      #logo-bv {
        display: none;
      }
    }

    /* Header */

    .header-wrapper {
        height: 70px;
    }

    .navbar-nav {
      display: none;
      width: 100%;
    }

    body.nav-open {
      .navbar-nav {
        display: inline-block;
      }
    }

    .header-wrapper-home {
      height: 75px;
    }

    header#main {
      margin: 0;
      padding: 0;
      nav {
        margin: 0;
        padding: 0;
      }
    }

    header#main .navbar-header .navbar-toggle {
      top: -126px;
      position: absolute;
      right: 0px;
      z-index: 999;
      display: inline-block;
    }

    header#main .navbar-default {
      background: transparent;
      border: none;

      .navbar-nav {
        li:first-child {
          border: none;

          &.active a {
            border: none;
            line-height: 48px;
            border-bottom: 1px solid white;

          }
        }

        li {
          margin: 0 !important;
          border: none;
          float: none;
        }
      }

      .navbar-collapse {
        border: none;
        box-shadow: none;

        ul {
          top: 2px;
          left: 0;
          width: 100%;
          position: absolute;
          background-color: $primary-color;
          text-align: center;

          li {
            margin: 0;
            a {
              width: 100%;
              border-bottom: 1px solid white;
            }
          }
        }

        .telmob, .shopmob {
            top: -46px;
        }

        #logo img {
            padding: 9px 0;
            max-width:130px;
        }
      }
    }

    header#main {
      height: 135px;

      .reserveren-scrolled {
        display: none;
      }

      .zoeken {
	      width: 100%;
        text-align: center;

  			input {
  				height: 36px;
  				border: 1px solid #c3c3c3;
  				border-radius: 0;
  				outline: 0;
  				background-color: $white-color;
  				padding: 0 0 0 15px;
  				margin: 0px;
  				float: inherit;
  				max-width: 100%;
  				font-size: 14px;
  			}

    		input[type=submit] {
    		    width: 40px;
    		    height: 36px;
    		    background-color: #EC3A0D;
    		    border-left: 2px solid #fff;
    		    position: absolute;
    			  display: inline-block;
    		    right: 0;
    		    top: 0;
    		    color: #fff;
    		    background-color:$secondary-color;
    		    background-image: url(assets/images/search-icon.png);
    		    background-position: center center;
    		    background-repeat: no-repeat;
    		}
		  }

      .telmob, .shopmob {
		    top: 15px;
		  }

      #logo img {
        padding: 10px 0;
        max-width:160px;
        z-index: 3;
      }
      #logo-bv-mobile img{
        max-width: 160px;
      }

      .breadcrumbs {
	      display: none;
		  }

    }

    /* Ambience photo */

    div#ambience-photo, div#ambience-photo2, div#notfound-headpic {
        height: 250px;
        margin-top: 50px;
    }

    /* Content page */

    section.content {

        .container {
            margin-top: -65px;
        }
    }

    section.shadowbox, section.content, section.notfound {
    	position: relative;
    	margin-bottom: 30px;

      	.container {
      		background-color: $white-color;
      		padding: 30px 15px;
      		z-index: 2;
      	 	box-shadow: 0px 0px 8px #919191;
      		text-align: center;
      		margin-top: -65px;
        }
    }

    /* Sidebar news */

    .sidebar-news {
        width: 46%;
        margin: 0 2% 30px 2%;
    }


    /* Page */

    section.page {
    	padding-top:80px;

        h1 {
            text-align: center;
        }

        .news {
          h1 {
            text-align: left;
          }
        }

        .productspecs {

            .titlebar, .titlebar.large, .titlebar.alt {
                display: none;
            }

            .infobar-even, .infobar-uneven, .infobar-uneven.large, .infobar-uneven.alt {
                padding: 0px;

                p {
                    padding: 5px 0 5px 15px;
                    width: 33%;

                    .hidden {
                        display: inline-block !important;
                        margin-right: 10px;
                    }

                    &.title {
                        background: #148cd1;
            			background: -moz-linear-gradient(top, #148cd1 0%, #157db8 100%);
            			background: -webkit-linear-gradient(top, #148cd1 0%,#157db8 100%);
            			background: linear-gradient(to bottom, #148cd1 0%,#157db8 100%);
            			width: 100%;
            			float: left;
            			padding:5px 15px;
                        color: $white-color;
                        text-align: center;
                        text-decoration: none;
                        font-weight: bold;
                    }
                }
            }
        }

        .gallery {
    		float: left;
            width: 40%;
            margin-bottom: 30px;

    		.picture {
    			border: 2px solid $primary-color;
    			position: relative;
    			display: inline-block;
    		}

    		.thumbs {
    			.thumb {
    				border: 2px solid #007abf;
    				float:left;
    				width:31%;
    				height:56px;
    				margin: -2px 0 0 -2px;

    				&:first-child {
    					margin-left: 0px;
    					width:30.8%;
    				}
    			}
    		}
    	}

    	.gallery-text {
            width: 55%;
            margin-left: 30px;
            margin-bottom: 30px;
    	}

        .table-info {
            margin-bottom: 30px;
            width: 100%;

            .title {
                text-align: center;
            }

            a {
                width: 100%;
            }

    		form {

    			input[type=submit] {
    				width: 100%;
                    position: static;
    			}

    		}
    	}
    }

    section.notfound .container .contact-info h3 {
      margin: 0px 0 20px 0;
      font-size: 18px;
    }

    /* Footer */

    footer {
        padding: 15px;

        div.col-xs-12 {
            margin-top: 20px;
            height: 300px;
        }
    }
   .logo-head {
    width: 50%;
    float: left;
   }
    header#main .menu-s{
    width: 50%;
    float: left;
    padding-left: 0px;
    padding-right: 15px;
    padding-top: 90px;
   }
   header#main .account-link{
    top: 15px;
   }
  section.shadowbox .container .home-category, section.content .container .home-category, section.notfound .container .home-category{
    background-size: cover;
   }
}

@media screen and (max-width: 480px) {
    header#main {
    height: 120px;
    }
   header#main .logo-head {
    width: 50%;
    float: left;
   }
    header#main .menu-s{
    width: 50%;
    float: left;
    padding-left: 0px;
    padding-right: 15px;
    padding-top: 70px;
   }
   header#main #logo img,
   header#main #logo-bv-mobile img {
    max-width: 135px;
   }
   header#main .telmob{
    right: 52px;
    top: 13px;
   }
   header#main .shopmob{
    right: 88px;
    top: 13px;
   }
   header#main .account-link{
    right: 125px;
    top: 13px;
   }
   header#main .navbar-header .navbar-toggle {
    top: -111px;
    position: absolute;
    right: 0px;
    z-index: 999;
  }
  header#main .navbar-default .navbar-collapse ul {
    top: 5px;
  }
   div#ambience-photo {
      height: 200px;;
      margin-top: 40px;
    }
   section.page{
   .productspecs.cat-support{
   .infobar-uneven.large{
   p{
   width: 45%;
   &:first-child{
   width: 100%;
   }
   &:nth-child(4){
   width: 15%;
   }
   }
   }
   }
   }
}

@media only screen and (min-width: 390px) and (max-width: 480px){
  header#main .navbar-header .navbar-toggle {
  top: -108px;
  }
}

@media screen and (max-width: 425px) {

    /* Header */

    .navbar-nav {
      display: none;
    }

    body.nav-open {
      .navbar-nav {
        display: inline-block;
      }
    }

    header#main {
      margin: 0;
      padding: 0;
      nav {
        margin: 0;
        padding: 0;
      }
    }

    header#main .navbar-default {
      background: transparent;
      border: none;

      .navbar-nav {
        li:first-child {
          border: none;

          &.active a {
            border: none;
            line-height: 48px;
          }
        }

        li {
          border: none;
        }
      }

      .navbar-collapse {
        border: none;
        box-shadow: none;


        ul {
          top: 5px;
          width: 100%;
          position: absolute;
          background-color: $primary-color;
          text-align: center;

          li {
            a {
              width: 100%;
            }
          }
        }

        .telmob, .shopmob {
            top: -46px;
        }

        #logo img {
            padding: 9px 0;
            max-width:130px;
        }
      }
    }

    /* Ambience photo */

    div#ambience-photo {
      height: 200px;;
      margin-top: 40px;
    }

    div#ambience-photo2, div#notfound-headpic {
        height: 150px;
        margin-top: 40px;
    }

    section.shadowbox {

        .container {
            padding: 30px 30px 30px 30px;

            h1 {
                color: #007abf;
                font-size: 26px;
            }

            .contact-info {

                h3 {
                    font-size: 22px;
                }
            }
        }
    }

    /* Content page */

    section.content {

    	.container {
    		margin-top: -65px;
    	}
    }

    /* Sidebar news */

    .sidebar-news {
        width: 100%;
        margin: 0 0 30px 0;
    }

    /* Page */

    section.page {
    	padding-top:60px;

      .introtext {
    		margin-bottom: 30px;
    	}

      .news {
        img {
          display: inline-block;
          margin: 20px 0;
        }
      }

        .product {
            .image {
                width: 100%;
                text-align: center;
            }
        }

        .productspecs {

            .infobar-even, .infobar-uneven, .infobar-uneven.large, .infobar-uneven.alt {
                position: relative;

                p {
                    padding: 5px 0 5px 15px;
                    width: 50%;
                    position: relative;

                    .hidden {
                        display: block !important;
                        margin-right: 10px;
                    }

                    &.title {
                        background: #148cd1;
            			background: -moz-linear-gradient(top, #148cd1 0%, #157db8 100%);
            			background: -webkit-linear-gradient(top, #148cd1 0%,#157db8 100%);
            			background: linear-gradient(to bottom, #148cd1 0%,#157db8 100%);
            			width: 100%;
            			float: left;
            			padding:5px 15px;
                        color: $white-color;
                        text-align: center;
                        text-decoration: none;
                        font-weight: bold;
                    }

                    &.button {
                        position: absolute;
                        bottom: 0px;
                        float: none;
                        margin: 0px;
                        right: 0px;
                    }
                }
            }
        }

        .gallery {
            width: 100%;

    		.thumbs {
    			.thumb {
    				width:33.6%;
    				height:81px;

    				&:first-child {
    					width:33.6%;
    				}
    			}
    		}
    	}

    	.gallery-text {
    		width: 100%;
            margin-left: 0px;
    	}

        .table-info {
    		form {
    			input[type=submit] {
    				width: 100%;
    			}
    		}
    	}
    }

    /* Footer */

    footer {
        padding: 15px;

        div.col-xs-12 {
            margin-top: 20px;
            height: auto;
        }
    }
}
