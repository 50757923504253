.woocommerce table.shop_table {
    border: 1px solid rgba(0,0,0,.1);
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
    border-collapse: separate;
    border-radius: 0px;
    background-color: #f9f9f9;

    thead {
        &:first-of-type {
            background-color: #222;
            color: #fff;
            padding: 10px 0;

            tr {
                height: 46px;
            }
        }
    }

    th {
        font-weight: 500;
        padding: 15px 12px;
    }

    tr {
        border-radius: 0px;
    }

    .zonder-msg {
    float: left;
    width: 100%;
    padding: 5px 0px 10px;
    }

    .zonder-msg1 {
    float: left;
    width: 100%;
    padding: 5px 0px 10px;
    display: none;
    }
}

#add_payment_method table.cart img, .woocommerce-cart table.cart img, .woocommerce-checkout table.cart img {
    width: 50%;
    padding: 10px;
}

.woocommerce #respond input#submit.disabled, .woocommerce #respond input#submit:disabled, .woocommerce #respond input#submit:disabled[disabled], .woocommerce a.button.disabled, .woocommerce a.button:disabled, .woocommerce a.button:disabled[disabled], .woocommerce button.button.disabled, .woocommerce button.button:disabled, .woocommerce button.button:disabled[disabled], .woocommerce input.button.disabled, .woocommerce input.button:disabled, .woocommerce input.button:disabled[disabled] {
    color: #fff;
    opacity: 1;
}

.woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt {
    background-color: #00b900;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    border-radius: 0px;
    padding: 20px 30px;
    font-size: 18px;
    margin-top: 10px;

    &:hover {
        background-color: #0b84df;
    }
}

.woocommerce #content table.cart td.actions, .woocommerce table.cart td.actions, .woocommerce-page #content table.cart td.actions, .woocommerce-page table.cart td.actions {
    height: 74px;
}

#coupon_code {
    display: inline-block;
    margin-right: 20px;
    padding-left: 10px;
    width: 190px;
}

.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button {
    border-radius: 0px;
    color: #fff;
    background-color: #0b84df;

    &:hover {
        background-color: #0a77ca;
        color: #fff;
    }
}

.woocommerce a.remove {
    line-height: 0.8;
    margin-left: 15px;
}

@media screen and (max-width: 768px) {
    .woocommerce table.shop_table,
    .woocommerce table.shop_table thead,
    .woocommerce table.shop_table tbody,
    .woocommerce table.shop_table th,
    .woocommerce table.shop_table td,
    .woocommerce table.shop_table tr {
        display: block;
    }

    .woocommerce table.shop_table thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .woocommerce table.shop_table td {
        position: relative;
        padding-left: 50% !important;
    }

    .woocommerce table.shop_table {
        border: none;
    }

    .woocommerce table.shop_table td.product-spacer {
        border-color: #FFF;
        height: 10px;
    }

    .woocommerce table.shop_table td:before {
        position: absolute;
        top: 10px;
        left: 0px;
        width: 25%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        padding-right: 10px;
        padding-left: 10px;
        text-transform: uppercase;
        font-size: 15px;
    }

    .woocommerce table.shop_table td.c-extra-row {
    padding-left: 20px !important;
    float: left !important;
    text-align: center !important;
    }

   .woocommerce a.remove {
    display: block;
    }

   .product-Aantal .col-lg-2 {
    padding: 0;
    text-align: right;
    }

    .product-Aantal .col-lg-2.clearfix .qty-increment{
    float: right;
    }

    .shop_table.shop_table_responsive.cart.woocommerce-cart-form__contents.sp-left-col td {
    padding-left: 15px !important;
    text-align: left !important;
    }

    .shop_table.shop_table_responsive.cart.woocommerce-cart-form__contents.sp-left-col td:before{
    content: none;
    }

   .shop_table td.hiderow {
    display: none !important;
    }

   .woocommerce table.shop_table td.c-extra-row::before {
    content: none;
    }

    .woocommerce table.shop_table td.product-remove {
        background-color: #222 !important;
    }

    .woocommerce table.shop_table td.product-remove:before {
        content: "DELETE";
        text-align: left;
        padding-left: 10px;
    }

    .woocommerce table.shop_table td.product-thumbnail:before {
        content: "IMAGE";
        text-align: left;
        padding-left: 10px;
    }

    .woocommerce table.shop_table td.product-name:before {
        content: "PRODUCT";
        text-align: left;
        padding-left: 10px;
    }

    .woocommerce table.shop_table td.product-price:before {
        content: "PRICE";
        text-align: left;
        padding-left: 10px;
    }

    .woocommerce table.shop_table td.product-quantity:before {
        content: "QUANTITY";
        text-align: left;
        padding-left: 10px;
    }

    .woocommerce table.shop_table td.product-subtotal:before {
        content: "SUBTOTAL";
        text-align: left;
        padding-left: 10px;
    }

    .woocommerce table.shop_table td.product-total:before {
        content: "TOTAL";
        text-align: left;
        padding-left: 10px;
    }

    .woocommerce .quantity,
    .woocommerce #content .quantity,
    .woocommerce .quantity,
    .woocommerce #content .quantity {
        margin: 0;
    }

    .woocommerce table.cart td.actions,
    .woocommerce #content table.cart td.actions {
        text-align: left;
        border:0;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .woocommerce table.cart td.actions .button.alt,
    .woocommerce #content table.cart td.actions .button.alt {
        float: left;
        margin-top: 10px;
    }

    .woocommerce table.cart td.actions div,
    .woocommerce #content table.cart td.actions div,
    .woocommerce table.cart td.actions input,
    .woocommerce #content table.cart td.actions input {
        margin-bottom: 10px;
    }

    .woocommerce .cart-collaterals .cart_totals {
        float: left;
        width: 100%;
        text-align: left;
        margin-top: 30px;
    }

    .woocommerce .cart-collaterals .cart_totals th,
    .woocommerce .cart-collaterals .cart_totals td {
        border:0 !important;
    }

    .woocommerce .cart-collaterals .cart_totals table tr.cart-subtotal td,
    .woocommerce .cart-collaterals .cart_totals table tr.shipping td,
    .woocommerce .cart-collaterals .cart_totals table tr.total td {
        padding-left: 6px !important;
    }

    .woocommerce table.shop_table tr.cart-subtotal td,
    .woocommerce table.shop_table tr.shipping td,
    .woocommerce table.shop_table tr.total td,
    .woocommerce table.shop_table.order_details tfoot th,
    .woocommerce table.shop_table.order_details tfoot td {
        padding-left: 6px !important;
        border:0 !important;
    }

    .woocommerce table.shop_table tbody {
        padding-top: 0px;
    }

    .woocommerce .col2-set .col-1,
    .woocommerce .col2-set .col-1,
    .woocommerce .col2-set .col-2,
    .woocommerce .col2-set .col-2,
    .woocommerce form .form-row-first,
    .woocommerce form .form-row-last,
    .woocommerce form .form-row-first,
    .woocommerce form .form-row-last {
        float: none;
        width: 100%;
    }

    .woocommerce .order_details ul,
    .woocommerce .order_details ul,
    .woocommerce .order_details,
    .woocommerce .order_details {
        padding:0;
    }

    .woocommerce .order_details li,
    .woocommerce .order_details li {
        clear: left;
        margin-bottom: 10px;
        border:0;
    }

    #content table.cart td.actions .button,
    .woocommerce #content table.cart td.actions .input-text,
    .woocommerce #content table.cart td.actions input,
    .woocommerce table.cart td.actions .button,
    .woocommerce table.cart td.actions .input-text,
    .woocommerce table.cart td.actions input,
    .woocommerce #content table.cart td.actions .button,
    .woocommerce #content table.cart td.actions .input-text,
    .woocommerce #content table.cart td.actions input,
    .woocommerce table.cart td.actions .button,
    .woocommerce table.cart td.actions .input-text,
    .woocommerce table.cart td.actions input {
        width: 100%;
        font-size:12px !important;
    }

    .woocommerce table.cart td.actions .coupon .button, .woocommerce table.cart td.actions .coupon .input-text, .woocommerce table.cart td.actions .coupon input {
        width: 100%;
        font-size: 12px !important;
    }

    .woocommerce tfoot{
        display:block !important;
    }
    .woocommerce tfoot td{
        width:100% !important;
        display:block !important;
    }

    #content table.cart td.actions .coupon .button,
    .woocommerce #content table.cart td.actions .coupon .input-text,
    .woocommerce #content table.cart td.actions .coupon input,
    .woocommerce table.cart td.actions .coupon .button,
    .woocommerce table.cart td.actions .coupon .input-text,
    .woocommerce table.cart td.actions .coupon input,
    .woocommerce #content table.cart td.actions .coupon .button,
    .woocommerce #content table.cart td.actions .coupon .input-text,
    .woocommerce #content table.cart td.actions .coupon input,
    .woocommerce table.cart td.actions .coupon .button,
    .woocommerce table.cart td.actions .coupon .input-text,
    .woocommerce table.cart td.actions .coupon input {
        font-size:12px !important;
    }

    #coupon_code {
        margin-left: 0px;
        width: 180px;
    }

    #content table.cart td.actions .coupon,
    .woocommerce table.cart td.actions .coupon,
    .woocommerce #content table.cart td.actions .coupon,
    .woocommerce table.cart td.actions .coupon {
        margin-top: 1.5em;
    }

    #content table.cart td.actions .coupon .input-text,
    .woocommerce table.cart td.actions .coupon .input-text,
    .woocommerce #content table.cart td.actions .coupon .input-text,
    .woocommerce table.cart td.actions .coupon .input-text {
        margin-bottom: 1em;
    }

    .woocommerce .cart-collaterals .cross-sells,
    .woocommerce .cart-collaterals .cross-sells {
        display: block;

        ul.products {
            li {
                width: 100%;
            }
        }
    }

    .woocommerce table.shop_table {
        border: 1px solid rgba(0, 0, 0, 0.1);

        tr {
            border-radius: 0px;
            height: auto !important;
        }
    }

    .woocommerce-page table.cart .product-thumbnail {
        display: block;
    }

    .woocommerce table.shop_table td {
        padding: 6px 12px 14px 12px;
        a {
            position: relative;
            top: 3px;
        }
    }

    span.woocommerce-Price-amount.amount {
        position: relative;
        top: 3px;
    }

    .woocommerce table.shop_table td small {
        font-weight: 400;
        position: relative;
        top: 3px;
    }

    .woocommerce table.shop_table thead:first-of-type {
        display: none;
    }

    .woocommerce #content table.cart td.actions, .woocommerce table.cart td.actions, .woocommerce-page #content table.cart td.actions, .woocommerce-page table.cart td.actions {
        height: auto;
    }
}

.attachment-shop_thumbnail.size-shop_thumbnail.wp-post-image {
   max-width: 80px;
   padding: 10px 0;
}

.checkout-button.button.alt.wc-forward {
  float: right;
  padding: 20px 35px !important;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
}

input.button.update-cart {
  padding: 0px 20px !important;
  height: 50px;
  font-size: 14px;
  text-transform: uppercase;
  background-color: $primary-color;
}

.woocommerce table.shop_table {
  button {
    padding: 0px 20px;
  }
}

div.cart_totals.calculated_shipping {
  h2 {
    margin:20px 0 15px 0;
  }
}

.woocommerce a.remove {
  i {
    color: #d91015;
  }
}

.woocommerce a.remove:hover {
    color: #fff!important;
    background: none;
}
